// import store from "@/store"
export const permission = {
    mounted(el,binding){
        const permissions = ['人事助手','审批','考勤统计','账号关联']
        const {value} = binding
        if(!permissions.includes(value)){
            el.parentNode && el.parentNode.removeChild(el);
        }
        // const userPermission = store.state.user_info.permission
        // if(!value){
        //     el.parentNode && el.parentNode.removeChild(el);
        // }
    }
}