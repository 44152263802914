import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import generateColors from '@/utils/color';
export default {
  components: {
    ElConfigProvider
  },
  setup() {
    const localColor = localStorage.getItem('color') || 'rgb(19,96,244)';

    // const  detectDeviceType = () => {
    //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //   // 检查是否为移动设备
    //   if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)) {
    //       return true;
    //   } else {
    //       return false;
    //   }
    // }
    // const isMobileDevice = detectDeviceType();
    // // 根据设备类型设置页面标题
    // document.title = isMobileDevice ? '中航人力助手' : '航融管家';
    const changePrimaryColor = value => {
      const domStyle = document.documentElement.style;
      let colorMap = generateColors(value);
      domStyle.setProperty('--el-color-primary', value);
      //生成不同1-9的透明度
      Object.keys(colorMap).forEach(key => {
        document.documentElement.style.setProperty(`--el-color-primary-${key}`, colorMap[key]);
      });
      localStorage.setItem('color', value);
    };
    changePrimaryColor(localColor);
    return {
      zhCn
    };
  }
};